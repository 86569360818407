import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import {get, omit} from 'lodash'
import { isExternalUrlOrHash, contentfulMapToLocalized } from "../../helpers.js"
import Img from 'gatsby-image'
import styles from './author.scss'
import { FaEnvelope, FaFacebookSquare, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { authorUrl } from "../../helpers.js"

class Author extends React.Component {
  constructor(props) {
    super(props);
    this.data = get(this.props.node, 'data.site.siteMetadata')
    if (this.props.author) {
      //This expects an author from Contentful
      this.author = {
        name: get(this.props.author, 'name', ''),
        title: get(this.props.author, 'title'),
        bio: get(this.props.author, 'shortBio.childMarkdownRemark.internal.content'),
        image: get(this.props.author, 'image', null),
        networks: {
          facebook: get(this.props.author, 'facebook', null),
          linkedIn: get(this.props.author, 'linkedIn', null),
          twitter: get(this.props.author, 'twitter', null),
          instagram: get(this.props.author, 'instagram', null),
          email: get(this.props.author, 'email', null)
        }
      }
    }
    this.publisher = this.props.publisher
  }
  render() {
    let authorHtml

    const socialIcons = {
      facebook: <FaFacebookSquare className="icon" />,
      linkedIn: <FaLinkedin className="icon" />,
      twitter: <FaTwitter className="icon" />,
      instagram: <FaInstagram className="icon" />,
      email: <FaEnvelope className="icon" />
    }

    if (this.author) {
      let imgElement = ''
      const nameElement = (
        <>
          <span itemProp="name">{ this.author.name }</span>
          { this.author.title ? (
            <>
              , <span itemProp="honorificSuffix">{ this.author.title }</span>
            </>
          ) : ''}
        </>
      )
      if (this.author.image) {
        if (this.author.image.fixed) {
          imgElement = (<Img className="author-image" fixed={ this.author.image.fixed } alt={ this.author.name }/>)
        } else if (this.author.image.fluid) {
          imgElement = (<Img className="author-image" fluid={ this.author.image.fluid } alt={ this.author.name }/>)
        }
      }
      authorHtml = (
        <>
          { imgElement }
          { this.props.nameAsLink ? <div className="author-bio-container"><span className="author-bio author-by d-none d-md-block">Article by:</span><Link to={authorUrl(this.author)} rel="author" className="author-link"><h4>{nameElement}</h4></Link></div> : <h1>{ nameElement }</h1> }
          <p className="author-bio">{ this.author.bio }</p>
          { this.props.showNetworks ?
            <p className="networks">
              { Object.keys(this.author.networks).map( (network, index) => {
                if (this.author.networks[network]) {
                  return (
                    <a href={ `${network === 'email' ? 'mailto:' : ''}${this.author.networks[network]}` } target="_blank" key={ index }>
                      { socialIcons[network] }
                    </a>
                  )
                }
              } ) }
            </p> : ''
          }
          <div className="clearfix"></div>
        </>
      )
    } else {
      authorHtml = (<meta itemProp="name" content={ this.data.author.name } />)
    }


    return (
      <>
        <div className="author bg-author p-md-4 mb-md-5 py-3 px-3" itemProp="author" itemScope itemType="https://schema.org/Person">
            { authorHtml }
        </div>
      </>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
    query authorQuery {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `)
  return (
    <Author  {...props} data={data}/>
  )
};

