import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Author from '../components/contentful/author'
import { Button } from 'react-bootstrap'
import Section from "../components/section/section";
import {buildBreadcrumbsItem} from "../helpers";

class AuthorTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.author = this.props.data.author
    this.authorPageUrl = this.props.location.href
    this.posts = get(this.props.data, 'posts.edges', []) || []
    const pageTitle = `${this.props.data.author.name} | Therapetic`
    this.seo = {
      children: [
        <link rel="canonical" href={ this.authorPageUrl } key='canonical-url'  />
      ],
      title: pageTitle,
      seoTitle: pageTitle,
      description: this.author.metaDescription || 'Therapetic',
      meta: {
        title: pageTitle,
        description: this.author.metaDescription || 'Therapetic',
        image: get(this.author, 'image.fixed'),
        ogMeta: {
          'og:type': 'author'
        }
      },
      disallowIndexing: this.author.disallowIndexing || false
    }
  }
  render() {
    let breadcrumbTitle = this.author.name && this.author.title ? this.author.name + ', ' + this.author.title : this.author.name

    const breadcrumbs = [
      buildBreadcrumbsItem(breadcrumbTitle, this.props.location.href)
    ]
    return (
      <Layout breadcrumbs={breadcrumbs} location={this.props.location} containerClassName={`author mt-0 author-${this.author.id}`}>
        <SEO { ...this.seo } />
        <Section className="section-author py-5 px-0">
          <div className="container">
            <Author author={this.author} showNetworks={true}/>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default AuthorTemplate

export const contentfulImageFixed = graphql`
  fragment contentfulImageFixed on ContentfulFixed {
    aspectRatio
    base64
    height
    src
    srcSet
    srcSetWebp
    srcWebp
    width
  }
`

export const contentfulImageFluid = graphql`
  fragment contentfulImageFluid on ContentfulFluid {
    aspectRatio
    base64
    sizes
    src
    srcSet
    srcSetWebp
    srcWebp
  }
`

export const contentfulPerson = graphql`
  fragment contentfulPerson on ContentfulPerson {
    disallowIndexing
    email
    facebook
    id
    metaDescription
    image {
      fluid(maxWidth:300) {
        ...contentfulImageFluid
      }
      fixed(width:300) {
        ...contentfulImageFixed
      }
    }
    instagram
    linkedIn
    name
    phone    
    shortBio {
      childMarkdownRemark {
        internal {
          content
        }
      }
    }
    title
    twitter
  }
`

export const authorTemplateQuery = graphql`
  query AuthorById($contentful_id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    author: contentfulPerson(contentful_id: { eq: $contentful_id }) {
      ...contentfulPerson
    }
  }
`
